import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshop,
  InternalInstructor,
  InternalTestimonial,
  InternalWorkshopTab
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import styles from './constelacao-familiar.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Constelacao Familiar' />
      <InternalHeader withType>
        <small>Online ou Presencial</small>
        <h1>
          Constelação <br />
          <strong>Familiar</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre o <strong>Workshop</strong>
            </h2>
            <p>
              A Constelação Sistêmica Familiar conversa com nossa Alma e com a Alma dos nossos antepassados, busca
              descortinar comprometimentos nessas relações que estão impedindo a conquista da nossa felicidade, harmonia
              ou saúde. É considerada uma terapia breve porque normalmente somente uma constelação é suficiente para
              trazer movimento no aspecto onde estamos encontrando dificuldade ou problema.
            </p>
            <p>
              Com a Constelação, é possível trazer a luz o que está impedindo nossa prosperidade, relacionamentos ou
              liberar do nosso campo traumas, tristezas, medos, desentendimentos e desencontros. Também é possível
              entender até mesmo o papel que as doenças estão tendo na nossa vida, pois, nossas dificuldades e problemas
              podem estar ligados a destinos de membros anteriores do nosso grupo familiar.
            </p>
            <h2>
              O que vamos <strong>vivenciar</strong>
            </h2>
            <p>
              Podemos participar do Workshop de Constelação trazendo um tema pessoal para ser trabalhado (Constelado) ou
              assistindo ao workshop e estando disponível para ser representante. Após um breve relato sobre a história
              familiar, apenas o essencial para que se acolham as informações dessa força maior, escolhemos algumas
              pessoas do grupo para representar membros importantes desse clã.
            </p>
            <p>
              Guiado pelas reações dos representantes e pela conexão com o sistema familiar do cliente, o terapeuta
              conduz, quando possível, os representantes até uma imagem de solução onde todos tenham um lugar e se
              sintam bem dentro do sistema familiar.
            </p>
            <p>
              Geralmente, se encerra a constelação com uma imagem de cura e um campo de força que nos traz um sentimento
              de paz, tranquilidade e equilíbrio, é como que esse campo maior nos acolhe e diz que tudo está bem agora,
              cada um encontrou seu lugar na família.
            </p>
          </InternalContent>
          <div className='aside'>
            <InternalWorkshop>
              <InternalWorkshopTab>
                <h3>
                  <small>Workshop à Distância</small>
                  Constelação <br />
                  Familiar
                </h3>
                <ul>
                  <li>3 horas por workshop</li>
                  <li>Sem pré-requisitos</li>
                  <li>Workshop aberto a todos os públicos</li>
                </ul>
                <a
                  href={WHATSAPPLINK}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={`button button--purpleReverse internalWorkshop-button`}
                >
                  Inscrição Workshop Online <small></small>
                </a>
              </InternalWorkshopTab>

              <InternalWorkshopTab>
                <h3>
                  <small>Workshop Presencial</small>
                  Constelação <br />
                  Familiar
                </h3>
                <ul>
                  <li>3 horas por workshop</li>
                  <li>Sem pré-requisitos</li>
                  <li>Workshop aberto a todos os públicos</li>
                </ul>
                <a
                  href={WHATSAPPLINK}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={`button button--purpleReverse button internalWorkshop-button`}
                >
                  Inscrição Workshop Presencial <small className={styles.newWindow}></small>
                </a>
              </InternalWorkshopTab>
            </InternalWorkshop>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
